import { h, render, Component } from "preact";
import "./assets/main.css";
import { AuthClient } from "@dfinity/auth-client";
import { HttpAgent } from "@dfinity/agent";

import icp from "./assets/icp_100_chain.svg";
import d_icon from "./assets/dragginz_icon_vectors_blue.svg";
import d_logo from "./assets/dragginz_logo_lower_blue.svg";
import { MyStorage } from "./Storage";

class App extends Component {
  state = {
    connected: false,
    isChrome: false,
    identityProvider: null,
    isButtonDisabled: false,
    isButtonHidden: false,
  };

  componentDidMount() {
    this.setupBrowserCheck();
    this.setupWebSocket();
    this.setState({ identityProvider: this.setupIdentityProvider() });  // Set the identityProvider here

    if (!this.state.isChrome) {
        this.checkConnection();
    }
}

  componentWillUnmount() {
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  setupIdentityProvider = () => {
    const url = new URL(window.location.href);
    const localhost = url.searchParams.get("localhost");
    return localhost
      ? "http://rdmx6-jaaaa-aaaaa-aaadq-cai.localhost:4943/#authorize"
      : "https://identity.ic0.app/#authorize";
  };

  setupBrowserCheck = () => {
    const isChromium = !!window.chrome;
    const isEdge = navigator.userAgent.includes("Edg");

    // Simplify the check to allow all Chromium browsers except Edge
    const isChrome = isChromium && !isEdge;
    this.setState({ 
        isChrome,
        disableButton: !isChrome // Disable button if not Chrome (excluding Edge)
    });
};

  handleClick = async (e) => {
    e.preventDefault();
    this.setState({ isButtonDisabled: true, isButtonHidden: true });

    await this.getIdentity();
};

  setupWebSocket = () => {
    const webSocket = new WebSocket("ws://localhost:8080/data");
    webSocket.addEventListener("open", () => {
      console.log("WebSocket connection established.");
      this.setState({ connected: true });
    });

    webSocket.addEventListener("close", () => {
      console.log("Websocket Closed");
    });

    webSocket.addEventListener("error", (e) => {
      console.log(e);
    });

    webSocket.addEventListener("message", (event) => {
      if (event.data == "Done") {
        console.log("Closing websocket");
        webSocket.close();
        window.close();
      }
    });

    this.webSocket = webSocket;
  };

  checkConnection = () => {
    console.log("checkConnection");
    if (!this.state.connected) {
      setTimeout(this.checkConnection, 1000);
    } else {
      this.getIdentity();
    }
  };

  sendMessage = (message) => {
    if (this.webSocket && this.state.connected) {
      this.webSocket.send(message);
    } else {
      console.log("WebSocket is not connected or not initialized.");
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  getIdentity = async () => {
    console.log("GetIdentity");
    try {
      const storage = new MyStorage("LiveStorage");
      const authClient = await AuthClient.create({ storage, keyType: 'Ed25519' });

      const isAuthenticated = await authClient.isAuthenticated();
      if (isAuthenticated) {
        console.log("User is authenticated");
        const identity = await authClient.getIdentity();
        this.sendMessage(JSON.stringify(identity));
      } else {
        console.log("User is not authenticated, proceeding to login");
        await this.handleLogin(authClient);
      }
    } catch (error) {
      console.error("Error in getIdentity:", error);
    }
  };

  async handleLogin(authClient) {
    // Define your authentication parameters
    const identityProvider = this.state.identityProvider;
    const onSuccess = async () => {
      console.log("Login successful");
      const identity = await authClient.getIdentity();
      this.sendMessage(JSON.stringify(identity));
    };

    const onError = (error) => {
      console.error("Login failed", error);
    };

    try {
      await authClient.login({
        identityProvider,
        onSuccess,
        onError,
        // Additional options can be included here
      });
    } catch (error) {
      console.error("Error during login:", error);
    }
  }

  render() {
    return (
      <div>
        <div className="centered-container">
          <img className="icon-dkp" src={d_icon} alt="Logo" />
          <img src={d_logo} alt="Logo" />
        </div>
        <p className="text-d">
          Press Continue to login with your Internet Identity.
        </p>
        <p className="text-d">This website is safe.</p>
        <div className="centered-container">
          <img className="icp-logo" src={icp} alt="Logo" />
          <button 
            id="click"
            class="btn"
            onClick={this.handleClick}
            disabled={this.state.isButtonDisabled}
            hidden={this.state.isButtonHidden}
            >
            Continue
          </button>
        </div>
      </div>
    );
  }
}

render(<App />, document.getElementById("app"));
