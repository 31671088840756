export class MyStorage {
    constructor(dbName = "MyStorage") {
        this.name = dbName;
    }

    /**
     * Retrieves a value by key from localStorage, parsing the stored JSON string.
     * @param {string} key - The key to retrieve.
     * @returns {Promise<string|null>} - The value associated with the key, or null if not found.
     */
    async get(key) {
        const fullKey = `${this.name}:${key}`;
        const item = localStorage.getItem(fullKey);
        return item ? JSON.parse(item) : null;  // Ensure to return the parsed item or null.
    }

    /**
     * Stores a value by key in localStorage after JSON-stringifying it.
     * @param {string} key - The key under which to store the value.
     * @param {string} value - The value to store, will be stringified.
     * @returns {Promise<void>}
     */
    async set(key, value) {
        const fullKey = `${this.name}:${key}`;
        localStorage.setItem(fullKey, JSON.stringify(value));
    }

    /**
     * Removes a key from localStorage.
     * @param {string} key - The key to remove.
     * @returns {Promise<void>}
     */
    async remove(key) {
        const fullKey = `${this.name}:${key}`;
        localStorage.removeItem(fullKey);
    }
}